<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="store" :query="{}"> </Mural>

    <main class="lesson-content q-my-lg">
      <div class="content-table row flex q-pt-xs">
        <div class="column col-12">
          <Table
            class="full-height"
            :list="orders"
            :columns="columnsTable"
            :addEdit="false"
            :addRemove="true"
            :addView="true"
            @view="(item) => view(item)"
            @disabled="(item) => disabled(item)"
            ><template v-slot:body="props">
              <div>
                <q-btn
                  dense
                  round
                  color="default-pink"
                  title="Visualizar"
                  flat
                  @click="() => view(props.props.row)"
                  icon="visibility"
                />
                <q-btn
                  dense
                  round
                  color="default-pink"
                  title="Remover"
                  flat
                  @click="() => disabled(props.props.row)"
                  icon="o_delete_outline"
                />
              </div>
            </template>
          </Table>
        </div>
      </div>

      <q-dialog
        v-model="modalCancel"
        persistent
        transition-show="scale"
        transition-hide="scale"
      >
        <q-card class="" style="width: 400px; border-radius: 25px">
          <q-card-section class="center card-icon-finished">
            <q-icon
              size="lg"
              color="green-4"
              class="material-icons-outlined rounder-icon"
              name="check"
            />
          </q-card-section>
          <q-card-section>
            <div class="center text-title-dialog">{{ messageDialog }}</div>
          </q-card-section>

          <q-card-actions align="right" class="center bg-white text-teal">
            <q-btn flat label="Ok" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <q-dialog
        v-model="modalCallCancelOrder"
        persistent
        transition-show="scale"
        transition-hide="scale"
      >
        <q-card style="min-width: 450px; border-radius: 25px">
          <q-card-section>
            <div class="center text-h6">
              Por que você deseja solicitar o cancelamento do pedido
              {{ order_id }}?
            </div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <q-select
              outlined
              bg-color="grey-3"
              color="default-pink"
              class="col-12"
              dense
              emit-value
              map-options
              v-model="reason_id"
              :options="reasons"
              :rules="[(val) => !!val || 'O campo fornecedor é obrigatório.']"
            />
          </q-card-section>

          <q-card-actions align="right" class="text-primary">
            <q-btn flat label="Cancelar" v-close-popup />
            <q-btn flat label="Remover" @click="cancelOrder" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </main>
    <q-inner-loading
      :showing="loadingPage"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import WareHouseService from "@/services/WareHouseService";
import Table from "@/components/shared/tables/TableDynamic.vue";
import { ref, onMounted } from "vue";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";
export default {
  name: "Order",
  components: {
    Mural,
    DefaultBtn,
    Table,
  },
  setup() {
    let _wareHouseService = new WareHouseService();
    let breadcrumbs = ref(["Meus Pedidos"]);
    let orders = ref([]);
    let modalCancel = ref(false);
    let modalCallCancelOrder = ref(false);
    let $q = new useQuasar();
    let router = new useRouter();
    let loadingPage = ref(false);
    let reason_id = ref(null);
    let order_id = ref(null);
    let messageDialog = ref("");
    let reasons = ref([]);

    let columnsTable = ref([
      {
        name: "order_id",
        align: "left",
        label: "ID",
        field: "order_id",
        sortable: false,
      },
      {
        name: "date_sale",
        align: "left",
        label: "Data da Solicitação",
        field: "date_sale",
        sortable: false,
      },
      {
        name: "total",
        align: "left",
        label: "Valor do Pedido",
        field: "total",
        sortable: false,
      },
      {
        name: "description_status",
        align: "left",
        label: "Status",
        field: "description_status",
        sortable: false,
      },
      {
        name: "evaluator_observation",
        align: "left",
        label: "Retorno de Solitação",
        field: "evaluator_observation",
        sortable: false,
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
        sortable: false,
      },
    ]);

    function view(order) {
      router.push({
        name: "order-details",
        query: { orderId: order.order_id },
      });
    }

    async function _getOrders() {
      loadingPage.value = true;
      let data = await _wareHouseService.getOrders();
      orders.value = data.data;
      loadingPage.value = false;
    }

    onMounted(() => {
      _getReason();
      _getOrders();
    });

    async function disabled(order) {
      const { is_cancel, description_status } = order;
      if (is_cancel) {
        if (description_status.includes("Cancelamento Concluído")) {
          messageDialog.value = "O cancelamento desse pedido já foi concluído!";
          modalCancel.value = true;
        } else {
          messageDialog.value =
            "O cancelamento desse pedido já está em andamento.";
          modalCancel.value = true;
        }
      } else {
        order_id.value = order.order_id;
        modalCallCancelOrder.value = true;
      }
    }

    async function cancelOrder() {
      await _wareHouseService.cancelOrder(order_id.value, reason_id.value);
      messageDialog.value = "O seu cancelamento foi solicitado com sucesso!";
      modalCancel.value = true;

      let index = orders.value.findIndex((order) => {
        return order.order_id == order_id.value;
      });

      orders.value[index].is_cancel = true;
      orders.value[index].description_status =
        orders.value[index].description_status + " | Cancelamento em Análise";
    }

    async function _getReason() {
      _wareHouseService
        .getReasonCancelOrder()
        .then((response) => {
          response.data.map((item) => {
            reasons.value.push({
              label: item.description,
              value: item.id,
            });
          });
        })
        .catch((e) => {});
    }

    return {
      breadcrumbs,
      orders,
      loadingPage,
      columnsTable,
      modalCancel,
      modalCallCancelOrder,
      messageDialog,
      reasons,
      reason_id,
      order_id,
      view,
      cancelOrder,
      disabled,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}
.content-card {
  width: 100%;
  height: 206px;
  background: #f7f7f7;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.main-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}

.content {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0.07em;
  color: #9c9c9c;

  .bold_number {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000 !important;
  }
}

.image-groups {
  border-radius: 20px;
}

.card-btn {
  box-shadow: 3px 10px 15px #00000033;
  width: 100px;
}

.center {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.description-product {
  max-height: 6rem;
  overflow: auto;
  border-radius: 0 !important;
}

.card-icon-finished {
  margin-top: 1.2rem;
  width: 61px;
  height: 61px;
  background: #daf8e1;
  font-style: normal;
  border-radius: 30px !important;
}
.rounder-icon {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #45dc67;
}

.text-title-dialog {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
}
</style>
